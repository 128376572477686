<template>
  <div>
    <v-card
      link
      :to="{
        name: 'member-management-config-courses-details',
        params: {
          organizationId: $route.params.organizationId,
          itemId: course.meta.id,
        },
      }"
      :outlined="outlined"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              :class="small ? 'font-weight-medium' : 'text-h6'"
              >{{ course.title }}</v-list-item-title
            >
            <v-list-item-subtitle>
              <data-chip
                :color="course.group.color"
                :text="course.group.title"
                :iconLeft="course.group.icon"
                class="mr-2 mt-2"
                small
              >
              </data-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "course-group-card",
  props: {
    course: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DataChip,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
