var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"link":"","to":{
      name: 'member-management-config-courses-details',
      params: {
        organizationId: _vm.$route.params.organizationId,
        itemId: _vm.course.meta.id,
      },
    },"outlined":_vm.outlined}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.small ? 'font-weight-medium' : 'text-h6'},[_vm._v(_vm._s(_vm.course.title))]),_c('v-list-item-subtitle',[_c('data-chip',{staticClass:"mr-2 mt-2",attrs:{"color":_vm.course.group.color,"text":_vm.course.group.title,"iconLeft":_vm.course.group.icon,"small":""}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }