<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="courseGroupLocal.title"
                          label="Bezeichnung"
                          outlined
                          hide-details="auto"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.number="courseGroupLocal.sortKey"
                          label="Sortierschlüssel"
                          hint="Qualifikationsgruppen werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                          type="number"
                          outlined
                          hide-details="auto"
                          prepend-inner-icon="mdi-sort-numeric-ascending"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="courseGroupLocal.icon"
                          label="Icon auswählen"
                          persistent-hint
                          required
                          prefix="mdi-"
                          :rules="[rules.required]"
                          outlined
                          hint="ID von Icon aus Material Design Icons Bibiliothek angeben"
                          prepend-inner-icon="mdi-emoticon"
                        >
                          <template v-slot:append-outer
                            ><v-btn depressed small @click="openMdiIconLibrary"
                              >Icons durchsuchen
                              <v-icon right>mdi-open-in-new</v-icon></v-btn
                            ></template
                          ></v-text-field
                        >

                        <v-avatar
                          :color="`${getMaterialColor(
                            courseGroupLocal.color
                          )} lighten-5`"
                          rounded
                          class="mr-4"
                        >
                          <v-icon :color="courseGroupLocal.color"
                            >mdi-{{ courseGroupLocal.icon }}</v-icon
                          >
                        </v-avatar>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="courseGroupLocal.color"
                          label="Farbe wählen"
                          persistent-hint
                          required
                          :rules="[rules.required]"
                          disabled
                          outlined
                          prepend-inner-icon="mdi-palette"
                        ></v-text-field>
                        <v-color-picker
                          v-model="courseGroupLocal.color"
                          :swatches="availableColors"
                          mode="hexa"
                          hide-inputs
                          hide-canvas
                          hide-sliders
                          show-swatches
                          dot-size="2"
                          width="1200"
                          swatches-max-height="150"
                        ></v-color-picker>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";

export default {
  name: "course-group-editor",
  props: {
    courseGroup: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "courseGroup",
    event: "courseGroupChange",
  },
  computed: {
    courseGroupLocal: {
      get: function () {
        return this.courseGroup;
      },
      set: function (value) {
        this.$emit("courseGroupChange", value);
      },
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {},
    openMdiIconLibrary() {
      var res = confirm(
        "Hinweis: Mit dem Öffnen der MDI Icon Library verlässt Du Mira One und wirst auf eine externe Internetseite weitergeleitet. Für die Inhalte können wir keine Haftung übernehmen.\n\nSuche nach einem passenden Icon und kopiere den Namen des Icons in das Eingabefeld."
      );
      if (res == true) {
        window.open("https://materialdesignicons.com/", "_blank");
      }
    },
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
          return "red";
        case "#E91E63FF":
          return "pink";
        case "#9C27B0FF":
          return "purple";
        case "#673AB7FF":
          return "deep-purple";
        case "#3F51B5FF":
          return "indigo";
        case "#2196F3FF":
          return "blue";
        case "#03A9F4FF":
          return "light-blue";
        case "#00BCD4FF":
          return "cyan";
        case "#009688FF":
          return "teal";
        case "#4CAF50FF":
          return "green";
        case "#8BC34AFF":
          return "light-green";
        case "#CDDC39FF":
          return "lime";
        case "#FFEB3BFF":
          return "yellow";
        case "#FFC107FF":
          return "amber";
        case "#FF9800FF":
          return "orange";
        case "#FF5722FF":
          return "deep-orange";
        case "#795548FF":
          return "brown";
        case "#607D8BFF":
          return "blue-grey";
        case "#9E9E9EFF":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
