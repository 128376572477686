<template>
  <div>
    <NavigationBar
      titel="Neuen Lehrgang anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <course-editor v-model="course"></course-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { CREATE_COURSE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CourseEditor from "@/components/member-management/courses/CourseEditor.vue";

export default {
  name: "member-management-course-new",
  components: {
    NavigationBar,
    CourseEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      course: {
        title: null,
        group: null,
        type: null,
        description: null,
        importTags: [],
      },
    };
  },

  methods: {
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${CREATE_COURSE}`, {
          organizationId: this.$route.params.organizationId,

          title: this.course.title,
          group: this.course.group,
          type: this.course.type,
          description: this.course.description,
          importTags: this.course.importTags,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
