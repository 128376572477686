<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <course-card :course="course"></course-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { DELETE_COURSE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import CourseCard from "@/components/member-management/courses/CourseCard.vue";
import {
  MEMBER_MANAGEMENT_COURSES_update,
  MEMBER_MANAGEMENT_COURSES_delete,
} from "@/data/permission-types.js";

export default {
  name: "member-management-course-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    CourseCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MEMBER_MANAGEMENT_COURSES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-config-courses-edit",
              params: {
                organizationId: this.$route.params.organizationId,
                itemId: this.$route.params.itemId,
              },
            });
          },
        },
        {
          title: "Löschen",
          permissions: `${MEMBER_MANAGEMENT_COURSES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          disabled: true,
          //   function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      course: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "course", data: this.course }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("courses")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.course = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    deleteItem() {
      var res = confirm(
        "Bist Du sicher, dass Du diesen Lehrgang löschen möchtest?"
      );
      if (res == true) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${DELETE_COURSE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        });
      }
    },
  },
};
</script>

<style></style>
