<template>
  <div>
    <NavigationBar
      titel="Qualifikationsgruppe bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <course-group-editor
          v-model="courseGroup"
          mode="new"
        ></course-group-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { UPDATE_COURSE_GROUP } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CourseGroupEditor from "@/components/member-management/courses/CourseGroupEditor.vue";

export default {
  name: "my-organization-skills-skill-edit",
  components: {
    NavigationBar,
    CourseGroupEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      skillGroup: null,
      skillsQuery: [],
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("courseGroups")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.courseGroup = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${UPDATE_COURSE_GROUP}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          title: this.courseGroup.title,
          sortKey: this.courseGroup.sortKey,
          color: this.courseGroup.color,
          icon: this.courseGroup.icon,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
